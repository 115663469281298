import { Content, Group, Item, Label, Root, Trigger } from "@radix-ui/react-dropdown-menu";
import { CopyIcon, EnvelopeClosedIcon, MixIcon } from "@radix-ui/react-icons";
import Button from "components/Button";
import H2 from "components/Typography/H2";
import { ReactNode } from "react";
const itemClassName = "gap-4";
interface ShareDropdownProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  onQrCodeOpen: (e: Event) => void;
  onShareMail: (e: Event) => void;
  onCopyLink: () => void;
  trigger: ReactNode;
  children: ReactNode;
}
const ShareDropdown = ({
  open,
  onOpenChange,
  onQrCodeOpen,
  onShareMail,
  onCopyLink,
  trigger,
  children
}: ShareDropdownProps) => <Root open={open} onOpenChange={onOpenChange} data-sentry-element="Root" data-sentry-component="ShareDropdown" data-sentry-source-file="index.tsx">
		<Trigger asChild data-sentry-element="Trigger" data-sentry-source-file="index.tsx">{trigger}</Trigger>
		<Content className="m-0 flex flex-col items-start gap-8 rounded border-[0.125rem] border-solid border-secondaryBackground bg-background p-4" collisionPadding={{
    top: 20,
    right: 20
  }} data-sentry-element="Content" data-sentry-source-file="index.tsx">
			<Label asChild data-sentry-element="Label" data-sentry-source-file="index.tsx">
				<H2 color="secondary" data-sentry-element="H2" data-sentry-source-file="index.tsx">Partager mon site vitrine</H2>
			</Label>
			<Group asChild data-sentry-element="Group" data-sentry-source-file="index.tsx">
				<div className="flex flex-col items-start">
					<Item asChild onSelect={onQrCodeOpen} data-sentry-element="Item" data-sentry-source-file="index.tsx">
						<Button className={itemClassName} type="button" variant="link" data-sentry-element="Button" data-sentry-source-file="index.tsx">
							<MixIcon data-sentry-element="MixIcon" data-sentry-source-file="index.tsx" />
							QR code
						</Button>
					</Item>
					<Item asChild onSelect={onShareMail} data-sentry-element="Item" data-sentry-source-file="index.tsx">
						<Button className={itemClassName} type="button" variant="link" data-sentry-element="Button" data-sentry-source-file="index.tsx">
							<EnvelopeClosedIcon data-sentry-element="EnvelopeClosedIcon" data-sentry-source-file="index.tsx" />
							Mail automatique
						</Button>
					</Item>
					<Item asChild onSelect={onCopyLink} data-sentry-element="Item" data-sentry-source-file="index.tsx">
						<Button className={itemClassName} type="button" variant="link" data-sentry-element="Button" data-sentry-source-file="index.tsx">
							<CopyIcon data-sentry-element="CopyIcon" data-sentry-source-file="index.tsx" />
							Copier le lien
						</Button>
					</Item>
				</div>
			</Group>
		</Content>
		{children}
	</Root>;
export default ShareDropdown;