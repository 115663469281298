"use client";

import { Action, Content, Description, Overlay, Portal, Root, Title, Trigger } from "@radix-ui/react-alert-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import ButtonLink from "components/Button/Link";
import ButtonLinkNext from "components/Button/Link/Next";
import Share from "components/Share";
import SlotTrack from "components/Slot/Track";
import Socials from "components/Socials";
import { PORTFOLIO, SERVICES } from "constants/links";
import { env } from "env.mjs";
import cn from "helpers/cn";
import { ComponentProps, ReactNode } from "react";
interface SidebarProps {
  children: ReactNode;
  onOpenAutoFocus?: ComponentProps<typeof Content>["onOpenAutoFocus"];
}
const Sidebar = ({
  children,
  onOpenAutoFocus
}: SidebarProps) => {
  return <Root data-sentry-element="Root" data-sentry-component="Sidebar" data-sentry-source-file="index.tsx">
			<Trigger asChild data-sentry-element="Trigger" data-sentry-source-file="index.tsx">{children}</Trigger>
			<Portal data-sentry-element="Portal" data-sentry-source-file="index.tsx">
				<Overlay className={cn("fixed inset-x-0 bottom-navbar top-0 z-navbar bg-background", "origin-bottom data-[state=closed]:animate-fold data-[state=open]:animate-unfold")} data-sentry-element="Overlay" data-sentry-source-file="index.tsx" />
				<Content onOpenAutoFocus={onOpenAutoFocus} className={cn("group fixed inset-x-0 bottom-navbar top-0 z-navbar flex shrink-0 origin-bottom flex-col bg-navbar", "data-[state=closed]:animate-fold data-[state=open]:animate-unfold")} data-sentry-element="Content" data-sentry-source-file="index.tsx">
					<VisuallyHidden data-sentry-element="VisuallyHidden" data-sentry-source-file="index.tsx">
						<Title data-sentry-element="Title" data-sentry-source-file="index.tsx">Menu</Title>
						<Description data-sentry-element="Description" data-sentry-source-file="index.tsx">
							Menu principal du site, tu y trouveras tous les liens pour me
							suivre.
						</Description>
					</VisuallyHidden>
					<div className="mb-auto flex w-full items-center justify-end px-5">
						{env.NEXT_PUBLIC_DISPLAY_SHARE === true && <Share />}
					</div>
					<div className={cn("flex flex-col items-start gap-4 px-5", "group-data-[state=closed]:animate-disappear group-data-[state=open]:animate-appear")}>
						<SlotTrack name="click sidebar link prestations" data-sentry-element="SlotTrack" data-sentry-source-file="index.tsx">
							<Action asChild data-sentry-element="Action" data-sentry-source-file="index.tsx">
								<ButtonLinkNext href={{
                pathname: "/",
                hash: SERVICES
              }} variant="link" data-sentry-element="ButtonLinkNext" data-sentry-source-file="index.tsx">
									Prestations
								</ButtonLinkNext>
							</Action>
						</SlotTrack>
						<SlotTrack name="click sidebar link portfolio" data-sentry-element="SlotTrack" data-sentry-source-file="index.tsx">
							<ButtonLink variant="link" href={PORTFOLIO} external target="_blank" data-sentry-element="ButtonLink" data-sentry-source-file="index.tsx">
								Portfolio
							</ButtonLink>
						</SlotTrack>
						<div className="flex w-full justify-center py-5">
							<Socials data-sentry-element="Socials" data-sentry-source-file="index.tsx" />
						</div>
					</div>
				</Content>
			</Portal>
		</Root>;
};
export default Sidebar;