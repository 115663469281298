import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Navbar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Slot/Track/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Toast/Provider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-d_za4rrwb4sot3wddmrkr2hczume/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["LazyMotion","domAnimation"] */ "/vercel/path0/node_modules/.pnpm/framer-motion@11.5.2_@emotion+is-prop-valid@0.8.8_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/framer-motion/dist/es/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.2/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.2/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.2/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.2/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Lora\",\"arguments\":[{\"subsets\":[\"latin\"],\"style\":[\"normal\"],\"variable\":\"--font-lora\"}],\"variableName\":\"lora\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.2/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Urbanist\",\"arguments\":[{\"subsets\":[\"latin\"],\"style\":[\"normal\"],\"variable\":\"--font-urbanist\"}],\"variableName\":\"urbanist\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/CBC_LOGO_48.svg");
