"use client";

import { Description, Overlay, Root } from "@radix-ui/react-dialog";
import { EnterIcon } from "@radix-ui/react-icons";
import IconButton from "components/IconButton";
import Input from "components/Input";
import { DialogClose, DialogContent, DialogTitle } from "components/Share/Dialog";
import SlotSubmit from "components/Slot/Submit";
import H3 from "components/Typography/H3";
import { useEffect, useRef } from "react";
// @ts-ignore
import { useFormState } from "react-dom";
import { share } from "server/actions";
interface ShareEmailProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  onSent: () => void;
  onError: () => void;
}
const ShareEmail = ({
  open,
  onOpenChange,
  onSent,
  onError
}: ShareEmailProps) => {
  const [state, formAction] = useFormState(share, undefined);
  const onSentRef = useRef(onSent);
  const onErrorRef = useRef(onError);
  useEffect(() => {
    if (state !== undefined) {
      return state?.success ? onSentRef.current() : onErrorRef.current();
    }
  }, [state]);
  return <Root open={open} onOpenChange={onOpenChange} data-sentry-element="Root" data-sentry-component="ShareEmail" data-sentry-source-file="index.tsx">
			<Overlay data-sentry-element="Overlay" data-sentry-source-file="index.tsx" />
			<DialogContent data-sentry-element="DialogContent" data-sentry-source-file="index.tsx">
				<DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="index.tsx">Partager la carte de visite</DialogTitle>
				<Description asChild data-sentry-element="Description" data-sentry-source-file="index.tsx">
					<H3 className="text-body" data-sentry-element="H3" data-sentry-source-file="index.tsx">
						Veuillez indiquer le mail du destinataire
					</H3>
				</Description>
				<form action={formAction}>
					<div className="flex gap-4">
						<Input label="Mail du destinataire" name="email" data-sentry-element="Input" data-sentry-source-file="index.tsx" />
						<SlotSubmit data-sentry-element="SlotSubmit" data-sentry-source-file="index.tsx">
							<IconButton size="small" type="submit" data-sentry-element="IconButton" data-sentry-source-file="index.tsx">
								<EnterIcon data-sentry-element="EnterIcon" data-sentry-source-file="index.tsx" />
							</IconButton>
						</SlotSubmit>
					</div>
				</form>
				<DialogClose data-sentry-element="DialogClose" data-sentry-source-file="index.tsx" />
			</DialogContent>
		</Root>;
};
export default ShareEmail;