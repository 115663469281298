import { Cancel, Content, Description, Overlay, Root, Title } from "@radix-ui/react-alert-dialog";
import Button from "components/Button";
import Image from "components/Image";
import H2 from "components/Typography/H2";
import H3 from "components/Typography/H3";
import QRCode from "public/CBC_QR_CODE.png";
interface DialogQRCodeProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}
const DialogQRCode = ({
  open,
  onOpenChange
}: DialogQRCodeProps) => {
  return <Root open={open} onOpenChange={onOpenChange} data-sentry-element="Root" data-sentry-component="DialogQRCode" data-sentry-source-file="index.tsx">
			<Overlay data-sentry-element="Overlay" data-sentry-source-file="index.tsx" />
			<Content className="absolute inset-0 flex flex-col items-center bg-background p-5" data-sentry-element="Content" data-sentry-source-file="index.tsx">
				<Title asChild data-sentry-element="Title" data-sentry-source-file="index.tsx">
					<H2 data-sentry-element="H2" data-sentry-source-file="index.tsx">QR Code</H2>
				</Title>
				<Description asChild data-sentry-element="Description" data-sentry-source-file="index.tsx">
					<H3 data-sentry-element="H3" data-sentry-source-file="index.tsx">Flasher le QR Code</H3>
				</Description>
				<div className="flex grow flex-col items-center justify-center">
					<Image width={300} height={300} src={QRCode} alt="QR Code" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
				</div>
				<div className="flex flex-col items-center justify-center">
					<Cancel asChild data-sentry-element="Cancel" data-sentry-source-file="index.tsx">
						<Button data-sentry-element="Button" data-sentry-source-file="index.tsx">Fermer</Button>
					</Cancel>
				</div>
			</Content>
		</Root>;
};
export default DialogQRCode;