"use client";

import { EyeClosedIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import IconButton from "components/IconButton";
import Input from "components/Input";
import { ComponentProps, useState } from "react";
interface InputPasswordProps extends ComponentProps<typeof Input> {}
const InputPassword = (props: InputPasswordProps) => {
  const [show, setShow] = useState(false);
  const onToggleShow = () => {
    setShow(prev => !prev);
  };
  return <Input autoComplete="current-password" type={show ? "text" : "password"} {...props} data-sentry-element="Input" data-sentry-component="InputPassword" data-sentry-source-file="index.tsx">
			<IconButton onClick={onToggleShow} className="absolute right-4" size="small" data-sentry-element="IconButton" data-sentry-source-file="index.tsx">
				{show ? <EyeOpenIcon /> : <EyeClosedIcon />}
			</IconButton>
		</Input>;
};
export default InputPassword;