"use client";

import { Description, Overlay, Root, Trigger } from "@radix-ui/react-dialog";
import { EnterIcon } from "@radix-ui/react-icons";
import IconButton from "components/IconButton";
import InputPassword from "components/Input/Password";
import { DialogClose, DialogContent, DialogTitle } from "components/Share/Dialog";
import SlotSubmit from "components/Slot/Submit";
import H3 from "components/Typography/H3";
import { ReactNode, useEffect, useRef } from "react";
// @ts-ignore
import { useFormState } from "react-dom";
import { unlock } from "server/actions";
interface ShareLockedProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  onUnlocked: () => void;
  onError: () => void;
  children: ReactNode;
}
const ShareLocked = ({
  open,
  onOpenChange,
  onUnlocked,
  onError,
  children
}: ShareLockedProps) => {
  const [state, formAction] = useFormState(unlock, undefined);
  const onUnlockedRef = useRef(onUnlocked);
  const onErrorRef = useRef(onError);
  useEffect(() => {
    if (state !== undefined) {
      return state?.success ? onUnlockedRef.current() : onErrorRef.current();
    }
  }, [state]);
  return <Root open={open} onOpenChange={onOpenChange} data-sentry-element="Root" data-sentry-component="ShareLocked" data-sentry-source-file="index.tsx">
			<Trigger asChild data-sentry-element="Trigger" data-sentry-source-file="index.tsx">{children}</Trigger>
			<Overlay data-sentry-element="Overlay" data-sentry-source-file="index.tsx" />
			<DialogContent data-sentry-element="DialogContent" data-sentry-source-file="index.tsx">
				<DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="index.tsx">Seuls les admins sont autorisés</DialogTitle>
				<Description asChild data-sentry-element="Description" data-sentry-source-file="index.tsx">
					<H3 className="text-body" data-sentry-element="H3" data-sentry-source-file="index.tsx">Veuillez vous identifier</H3>
				</Description>
				<form action={formAction}>
					<div className="flex gap-4">
						<InputPassword label="Mot de passe admin" name="password" data-sentry-element="InputPassword" data-sentry-source-file="index.tsx" />
						<SlotSubmit data-sentry-element="SlotSubmit" data-sentry-source-file="index.tsx">
							<IconButton size="small" type="submit" data-sentry-element="IconButton" data-sentry-source-file="index.tsx">
								<EnterIcon data-sentry-element="EnterIcon" data-sentry-source-file="index.tsx" />
							</IconButton>
						</SlotSubmit>
					</div>
				</form>
				<DialogClose data-sentry-element="DialogClose" data-sentry-source-file="index.tsx" />
			</DialogContent>
		</Root>;
};
export default ShareLocked;